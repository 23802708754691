import React from "react";

class Select extends React.Component {


    render() {
        return (
            <>
                <div className="d-flex align-items-center  nintex-section-2-spacing">
                    <select className="form-select" aria-label="Default select example" onChange={this.onChangeColor}>
                        <option value="0"  >Select ....</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </>
        )
    }

}


export default Select