import React from "react";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: "",
            email: "",
            bussinessemail: "",
            checkBox: "",

        };
    }
    onChangefirstName(e) {
        this.setState({ first_name: e.target.value })
    }
    onChangelastName(e) {
        this.setState({ last_name: e.target.value })
    }

    onChangeemail(e) {
        this.setState({ email: e.target.value })
    }

    onChangeBussinessEmail(e) {
        this.setState({ bussinessemail: e.target.value })
    }

    onChangeCheckbos(e) {
        this.setState({ checkBox: e.target.value })
    }

    validateData(obj){
       //return !!data;
    }


    render() {
        return (
            <>
                <div className="col-md-10  pt-0 col-lg-5 nintex-side-panel">
                    <div className="border rounded-3 nintex-main-form" >
                        <h4 className="mb-3 pb-3 pt-5 mt-3 fw-bold">Access your results</h4>
                        <div className="mb-3">
                        
                            <label htmlFor="First-Name" className="form-labe fw-normal" required>First Name*</label>
                            <input type="text" className="form-control" id="name" value={this.state.first_name} onChange={this.onChangefirstName.bind(this)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Last-Name" className="form-labe fw-normal">Last Name*</label>
                            <input type="text" className="form-control" id="lastname" value={this.state.last_name} onChange={this.onChangelastName.bind(this)} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Company" className="form-labe fw-normal">Company*</label>
                            <input type="input" className="form-control" id="company" value={this.state.email} onChange={this.onChangeemail.bind(this)} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Buisness Email" className="form-labe fw-normal">Business Email*</label>
                            <input type="email" className="form-control" id="bussiessEmail" value={this.state.bussinessemail} onChange={this.onChangeBussinessEmail.bind(this)} required/>
                        </div>
                        <div className="checkbox mb-3">
                            <label className="fw-bold">
                                <input type="checkbox" value="remember-me" value={this.state.checkBox} onChange={this.onChangeCheckbos.bind(this)} name="isdemo"/> I’m interested in a demo
                            </label>
                        
                        </div>
                        <div><small>* Required Field</small></div>
                        <button className="w-100 btn btn-primary btn-md rounded-pill side-content-button" type="submit">See your results</button>
                    </div>
                </div>
            </>
        )
    }

}


export default Form