import React from "react";

export const RATES = {
    1999: {
        per_envople_price: 2,
        min_range: 2000,
        max_range: 4999,
        add_on_range: 1.70,
    },
    5000:{
        per_envople_price: 1.85,
        min_range: 5000,
        max_range: 9999,
        add_on_range: 1.55,
    },
    10000: {
        per_envople_price: 1.70,
        min_range: 10000,
        max_range: 24999,
        add_on_range: 1.53,
    },
    25000:{
        per_envople_price: 1.60,
        min_range: 25000,
        max_range: 49999,
        add_on_range: 1.50,
    },
    50000: {
        per_envople_price: 1.55,
        min_range: 50000,
        max_range: 74999,
        add_on_range: 1.40,
    },
    75000:{
        per_envople_price: 1.50,
        min_range: 75000,
        max_range: 99999,
        add_on_range: 1.30,
    },
    100000: {
        per_envople_price: 1.45,
        min_range: 100000,
        max_range: 149999,
        add_on_range: 1.24,
    },
    200000:{
        per_envople_price: 1.38,
        min_range: 150000,
        max_range: 199999,
        add_on_range: 1.14,
    },
    300000: {
        per_envople_price: 1.32,
        min_range: 200000,
        max_range: 299999,
        add_on_range: 1.11,
    },
    400000:{
        per_envople_price: 1.25,
        min_range: 300000,
        max_range: 399999,
        add_on_range: 1.05,
    },
    500000: {
        per_envople_price: 1.20,
        min_range: 400000,
        max_range: 499999,
        add_on_range: 0.95,
    },
    600000:{
        per_envople_price: 1.15,
        min_range: 500000,
        max_range: 599999,
        add_on_range: 0.85,
    },
    700000: {
        per_envople_price: 1.10,
        min_range: 600000,
        max_range: 699999,
        add_on_range: 0.75,
    },
    800000:{
        per_envople_price: 1.05,
        min_range: 700000,
        max_range: 799999,
        add_on_range: 0.65,
    },
    900000: {
        per_envople_price: 1,
        min_range: 800000,
        max_range: 899999,
        add_on_range: 0.55,
    },
    1000000:{
        per_envople_price: 0.95,
        min_range: 900000,
        max_range: 1000000,
        add_on_range: 0.45,
    }
}