import React from 'react'
import image from '../../assets/images/nintex-logo-dark.png'


class Header extends React.Component {
    super() { }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg  head nintex-main-header">
                    <div className="container">
                    <a className="navbar-brand me-3 me-xl-4" href="https://www.nintex.com"><img className="d-block"
                            src={image} width="116" alt="Nintex-Logo" /></a>
                        <div className="col-md-3 text-end nintex-nav-right-content">
                            <a className="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3 nintex-free-trial-button"
                                href="https://www.nintex.com/trial/#esign" target={'_blank'}>Start free trial</a>
                        </div>
                    </div>
                </nav>
            </>
        )
    }

}


export default Header
