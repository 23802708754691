import React from "react";
import large from '../../assets/images/Main-page-larger-image-header.png'
import smaller from '../../assets/images/Main-page-smaller-image-header.png'
import backgroundImage from '../../assets/images/bg-1.svg'
import orange_circle from '../../assets/images/orange-circle.png'
import blue_circle from '../../assets/images/blue-circle.png'


class Banner extends React.Component {
    super() {

    }

    render() {
        return (
            <>
                <section
                    className="d-flex align-items-center  bg-size-cover bg-position-center  overflow-hidden pt-6 pb-lg-5 nintex-first-section" style={{
                        backgroundImage: `url(${backgroundImage})`
                    }} >
                    <div className="container" >
                        <div className="row align-items-center py-3">
                            <div className="col-xl-6 col-lg-5 d-flex justify-content-end">
                                <div
                                    className="pt-2 mx-auto mb-5 mb-lg-0 ms-lg-0 me-xl-7 text-center text-lg-start nintex-main-heading">
                                    <h1 className="fw-bold text-dark">Don’t pay too much for eSignature
                                    </h1>
                                    <p className="text-dark opacity-70 line-height-base" style={{ fontWeight: "500", fontSize: "16px" }} >
                                    Just answer these 5 questions and we'll show how much you can save with Nintex AssureSign.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7 d-none d-lg-block ">
                                <div className="parallax ms-lg-n5 nintex-first-image">
                                    <div className="parallax-layer position-relative" data-depth="0.1"
                                        style={{ position: "relative", display: "block", left: "0px", top: "0px" }}>
                                        <img src={large} alt="Layer" />
                                        <img className="circle-image1" src={orange_circle} />
                                            <img className="circle-image2" src={blue_circle} />
                                    </div>
                                    <div className="parallax-layer nintex-second-image"><img
                                        src={smaller} alt="Layer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}


export default Banner