import React from "react";

class Footer extends React.Component{
    super(){

    }

    render(){
        return(
            <>
                   <div className="copyright-nintex">
                    <div className="container">
                        <div className="nintex-footer-menu">
                            <ul>
                                <li><a href="https://www.nintex.com/legal/privacy-policy" style={{color:'white'}}>Privacy Policy</a></li>
                                <li><a href="https://www.nintex.com/legal/" style={{color:'white'}}>Legal</a> </li>
                                <li><a href="https://www.nintex.com/security/" style={{color:'white'}}>Security</a></li>
                                <li><a href="https://www.nintex.com/legal/website-and-community-terms-of-use/" style={{color:'white'}}>Terms of Use</a></li>
                            </ul>
                            <small>© 2022 Nintex UK Ltd</small>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Footer
