import React from 'react'
import button from '../../assets/images/savings-img.png'
import backgroundImage from '../../assets/images/bg-2.svg'
import CurrencyFormat from 'react-currency-format';


class Savings extends React.Component {

    constructor() {
        super();
        this.state = {
            perEnvelopSaving: 0,
            yearlySaving: 0,
            x: 0
        };
        this.getDataFromLocation();
    }
    getDataFromLocation() {
        let params = window.location.pathname.split('/');
        try {
            if (params.length < 4) {
                window.location = "/";
            } else {
                //this.state = {
                    //perEnvelopSaving: Math.round(params[2] * 100) / 100,
                    //yearlySaving: (Math.round(params[2] * 100) / 100 == null || Math.round(params[2] * 100) / 100 <= 0) ? 0.25*(parseInt(params[3])) : Math.round(params[3] * 100) / 100,
                    //x: (Math.round(params[3] * 100) / 100 == null || Math.round(params[3] * 100) / 100 < 0) ? 0.25*(parseInt(params[4])) : Math.round(params[4] * 100) / 100
                //};
                let envelopRate =  params[2];
                let perYearSaving  = params[3];
                let totalEnvelop = params[4];
                console.log('totalEnvelop', totalEnvelop);
                let perEnvelopSaving = 0 ;
                let finalYearlySaving = 0;
                if (envelopRate <= 0 || perYearSaving <= 0) {
                    finalYearlySaving = 0.25 * parseInt(totalEnvelop);
                    finalYearlySaving = Math.round(finalYearlySaving * 100)/ 100;
                } else {
                    finalYearlySaving =   Math.round(perYearSaving * 100) / 100
                }
                this.state = {
                    perEnvelopSaving: envelopRate,
                    yearlySaving: finalYearlySaving,
                };
            }
        } catch (e) {

        }
    }

    render() {
        return (
            <>
                <div className="middle-view" style={{ background: `url(${backgroundImage}), url(${backgroundImage})` }}>
                    <div className='container'>
                    <div className="d-flex justify-content-center">
                        <div className="white-circle col-12 col-lg-4 rounded-circle position-relative d-flex align-items-center justify-content-center">
                            <img className="w-75 rounded-circle" src={button} alt="icon" />
                        </div>
                    </div>

                    <div className="text-center middle-text  mx-auto mb-5">
                        <h4>Savings estimator</h4>
                        <p>Based on the information you provided your company could save up to:</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="bg-capsule col-6 d-flex flex-column flex-lg-row align-items-center rounded-pill">
                            <div className="bg-capsule-s rounded-pill text-center">
                            <CurrencyFormat value={this.state.perEnvelopSaving} displayType={'text'} thousandSeparator={true}  renderText={valueX => <div><h2>${(valueX==null) ? 0 : ((valueX < .01 ) ? '0.01 - $0.25' : valueX ) }</h2></div>} />
                                {/* <h4>${null == this.state.e ? 0 : this.state.e}</h4> */}
                                <h6>PER ENVELOPE</h6>
                            </div>
                            <h2 className="text-white display-1">|</h2>
                            <div className="text-center mt-3 mt-lg-0 bg-capsule-s rounded-pill">
                            <CurrencyFormat value={this.state.yearlySaving} displayType={'text'} thousandSeparator={true}  renderText={value => <div><h2 >${(value==null) ? 0 : ((value < .01 ) ? this.state.yearlySaving : value ) } </h2></div>} />
                                {/* <h4>${null == this.state.y ? 0 : this.state.y}</h4> */}

                                <h6>PER YEAR</h6>
                            </div>
                        </div>
                    </div>
                    <p className="text-center mt-3 mt-lg-5">Check your email for more details.</p>
                    </div>
                </div>
            </>
        )
    }

}


export default Savings