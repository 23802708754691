import React from 'react'
import HeroBanner from '../components/thankyou/HeroBanner'
import Savings from '../components/thankyou/Savings'
import Footer from '../components/thankyou/Footer'

class Thankyou extends React.Component{

    super(){

    }

    render(){
        return(
            <>
            <HeroBanner></HeroBanner>
            <Savings></Savings>
            <Footer></Footer>
            </>
        )
    }

}


export default Thankyou