import React from "react";
import Layout from "./components/Layouts/Layout";
import {Switch,BrowserRouter, Route,Routes, Link, Router, Redirect } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Thankyou from "./views/Thankyou";


class App extends React.Component {
  render() {
    return (

      <div className="wraper">
        <Layout />
      <BrowserRouter>
          <Routes>
            <Route exact path="/thankyou/:e/:y/:v" element={<Thankyou />} />
            <Route path="/" element={<Dashboard />}/>
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
        </div>
    )
  }
}

export default App;
