import React from 'react'
import Banner from '../components/Dashboard/Banner'
import Savings from '../components/Dashboard/Saving'
import MiddleBanner from '../components/Dashboard/MiddleBanner'
import WhyCompanies from '../components/Dashboard/WhyCompanies'
import Footer from '../components/Dashboard/Footer'
import icon1 from '../assets/images/icon-1.png'
import icon2 from '../assets/images/icon-2.png'
import icon3 from '../assets/images/icon-3.png'
import icon4 from '../assets/images/icon-4.png'
import icon5 from '../assets/images/icon-5.png'
import icon6 from '../assets/images/icon-6.png'
import icon7 from '../assets/images/icon-7.png'
import icon8 from '../assets/images/icon-8.png'




class Dashboard extends React.Component {
    state = {
        "Switch": {
            
            "Content": [
                {
                    "icon": icon1,
                    "title": "Fastest way to get documents signed",
                    "description": "Dynamically prepare and send contracts, order forms, and agreements for eSignature. Over 90% of documents are signed and returned within 24 hours, and most within two hours."
                },
                {
                    "icon": icon2,
                    "title": "Industry-leading SMS capability",
                    "description": "eSign via Text uses proprietary and secure technology to deliver digital documents to signers on-the-go."
                },
                {
                    "icon": icon3,
                    "title": "Transparent pricing with no overage penalties",
                    "description": "Don't pay too much for eSignature. We offer industry-leading pricing and volume discounts for most eSignature use cases."
                },
                {
                    "icon": icon4,
                    "title": "Custom branding & eSignature experience",
                    "description": "Sign administrators can manage and configure the entire signing process to match the desired customer experience and company branding."
                },
                {
                    "icon": icon5,
                    "title": "Unmatched compliance & security",
                    "description": "We meet global security and privacy standards with 256-bit encryption and ISO 27001 certified data centers."
                },
                {
                    "icon": icon6,
                    "title": "Powerful APIs & integrations",
                    "description": "We allow any organization to integrate Nintex AssureSign into existing business systems and processes with ease."
                },
                {
                    "icon": icon7,
                    "title": "Build dynamic documents within Salesforce",
                    "description": "We allow users to create, send, sign, track, and manage their dynamically generated business-critical documents without ever leaving salesforce."
                },
                {
                    "icon": icon8,
                    "title": "Easy to switch from DocuSign",
                    "description": "Migrate your eSignature templates directly from DocuSign to Nintex AssureSign with a simple converter tool and help from our customer success team."
                },
            ],
        }
    }


    render() {
        return (
            <>
                <Banner></Banner>
                <Savings></Savings>
                <MiddleBanner></MiddleBanner>
                <WhyCompanies title={ this.state.Switch }></WhyCompanies>
                <Footer></Footer>
            </>
        )
    }

}


export default Dashboard