import React from 'react'
import img_sm from '../../assets/images/Thank-you-page-smaller-image.png'
import img_lg from '../../assets/images/Thank-you-page-larger-image-header.png'
import backgroundImage from '../../assets/images/bg-1.svg'

class HeroBanner extends React.Component {
    super() {

    }

    render() {
        return (

            <>
            <section
                    className="d-flex align-items-center  bg-size-cover bg-position-center  overflow-hidden pt-6 pb-lg-5 nintex-first-section" >
             <div className='container  top-view bg-main g-0 '>
                <div className="row">
                    <div className="col-12 col-lg-6 body-text mx-lg-auto ">
                        <h1 className="fw-bold text-dark">Make the switch to Nintex AssureSign</h1>
                        <p className="text-dark opacity-70 line-height-base" style={{ fontWeight: "500", fontSize: "16px" }} >Secure eSignature, transparent pricing, no overage penalties.</p>
                    </div>
                    <div className="col-lg-6 image-design position-relative" style={{ background: `url(${backgroundImage})` }}>
                        <img className="rounded-circle w-25 img-fluid position-relative img-sm" src={img_sm} />
                        <img className="rounded-circle img-fluid w-50 position-relative img-lg" src={img_lg} />
                        <div className="rounded-circle bubble-sm position-relative"></div>
                        <div className="rounded-circle bubble-lg position-absolute"></div>
                    </div>
                </div>
            </div>
            </section>
            </>
        )
    }

}


export default HeroBanner