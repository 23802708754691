import React from 'react'
import backgroundImage from '../../assets/images/bg-img-3.png'
import bg_r from '../../assets/images/bg-2.svg'
import bg_l from '../../assets/images/bg-2.svg'

class WhyCompanies extends React.Component {

    super(props) {

    }

    render() {
        const results = this.props.title.Content
        const i = 10;
        return (
            <>

                <section className="d-flex  position-relative bg-size-cover  overflow-hidden pt-5 pb-lg-5 nintex-third-section" style={{
                    backgroundImage: `url(${backgroundImage})`
                }} >
                    <div className="bg-l"  style={{
                    backgroundImage: `url(${bg_l})`
                }} >
                        <div className="bg-r" style={{
                    backgroundImage: `url(${bg_r})`
                }}>
                            <div className="container">
                                <h2 className="fw-bold  pb-5">Here’s why companies are switching to Nintex AssureSign
                                </h2>
                                <div className="col-lg-8 w-100 justify-content-center">
                                    <div className="row gy-4">
                                        {results.map((e, index) => {
                                            return <div className="col-sm-6 mb-4" key={index}>
                                                <div className="d-flex">
                                                    <span className="icon icon-primary me-4 nintex-icons">
                                                        <img src={e.icon} />
                                                    </span>
                                                    <div className="flex-grow-1">
                                                        <h3>{e.title}</h3>
                                                        <p className="mb-0">
                                                            {e.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}


export default WhyCompanies