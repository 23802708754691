import React from 'react'
import Lower_Footer from '../Footer/Footer'

class Footer extends React.Component {

    super() {

    }

    render() {
        return (
            <>
                <section className="d-flex align-items-center position-relative   overflow-hidden pb-lg-5 nintex-blue-section">
                    <div className="container">
                        <div className="col-lg-7 text-center text-lg-start m-0 pt-5">
                            <h3>Powerful. Flexible. Convenient.</h3>
                            <p>Streamline your document-focused processes with Nintex AssureSign</p>
                            <a className=" btn btn-primary btn-sm rounded-pill mt-4 mb-2" href="https://www.nintex.com/trial/#esign" target="_blank">Try it for
                                free</a>
                        </div>
                    </div>

                </section>
                <Lower_Footer></Lower_Footer>
            </>
        )
    }

}


export default Footer