import React from 'react'
import Lower_Footer from '../Footer/Footer'


class Footer extends React.Component {

    super() {

    }

    render() {
        return (
            <>
            
                <div className="bottom-view bg-main">
                <div className='container'>
                    <p className="fw-bold fw-lg-normal mb-4 mb-lg-2">End the paper chase with</p>
                    <h5 className="fw-bold mb-4 d-none d-lg-block">Nintex AssureSign.</h5>
                    <a className="btn btn-primary rounded-pill head-button fw-bold" href="https://www.nintex.com/process-automation/e-signature/" target="_blank">Learn more
                    </a>
                </div>
                {/* <div className="bg-dark py-4 d-block d-lg-none"></div>  */}
               
                </div>
                <Lower_Footer></Lower_Footer>
            </>
        )
    }

}


export default Footer