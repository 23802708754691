import React from "react";
import saving from '../../assets/images/savings-img.png'
import Form from "./Form";
import Select from "./Select";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import {RATES} from './../../util/Rates';
import CurrencyFormat from 'react-currency-format';


class Saving extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 45000,
            eSignatureValue: 2,
            selecteSignature: 0,
            organization: 0,
            renewal: 0
        }
        this.submitData = this.submitData.bind(this);
        this.savingCalculator = this.savingCalculator.bind(this);
        this.getPartnerId = this.getPartnerId.bind(this);
    
    }
    onChangeColor = event => {
        console.log(event.target.value);
    }

    handleChange = value => {
        this.setState({
            value: value
        })
    };

    handleChangeeSignatureValue = value => {
        this.setState({
            eSignatureValue: Math.round(value * 100) / 100
        })
    };


    handleSignature(e) {
        this.setState({
            selecteSignature: e.target.value
        })
    }

    handleOrganization(e) {
        this.setState({
            organization: e.target.value
        })
    }

    async submitData(e){
        e.preventDefault();
        const company = e.target.company.value;
        const firstName = e.target.name.value;
        const bussiessEmail = e.target.bussiessEmail.value;
        const lastName = e.target.lastname.value;
        const provider = e.target.provider.value;
        const renewTime = e.target.renewTime.value;
        const crmUsed = e.target.crmUsed.value;
        const intesertedInDemo = e.target.isdemo.checked;

        console.log("intesertedInDemo",intesertedInDemo);
        if(company && firstName && bussiessEmail && lastName && provider && renewTime && crmUsed && this.state.value && this.state.eSignatureValue){

            const totalEnvolps =  this.state.value;
            const perEnvelopsRate = this.state.eSignatureValue;
            var rateKeys = Object.keys(RATES);
            
            rateKeys = rateKeys.filter((x)=>{return x < totalEnvolps});
            let key = rateKeys[0];
            let dataToConsider = RATES[key];
            let nintexCharge = dataToConsider['min_range'] * dataToConsider['per_envople_price'] + ( (totalEnvolps - dataToConsider['min_range']) * dataToConsider['add_on_range'] );
            let saving = (totalEnvolps * perEnvelopsRate) - nintexCharge;
            saving = Math.round(saving * 100) / 100;
            let perEnvelopsSaving = perEnvelopsRate - (nintexCharge / totalEnvolps);
            perEnvelopsSaving = Math.round(perEnvelopsSaving * 100) / 100;


            const formDatas = {
                se_varwhentostart: renewTime,
                se_varwhatcrm: crmUsed,
                se_varcurrentprovider: provider,
                se_varhowmanyesig: this.state.value,
                se_varwhatpriceperesig:this.state.eSignatureValue,
                se_varfirstname: firstName,
                se_varlastname: lastName,
                se_varcompany: company,
                se_varbusinessemail: bussiessEmail,
                se_vardemo :intesertedInDemo,
                se_varestsav: saving,
                se_varsavperenv: perEnvelopsSaving 
            }
            let partner_id = this.getPartnerId();
            if(null != partner_id && '' != partner_id) {
                formDatas.se_varpartnerid = partner_id;
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formDatas)
            };
            const response = await fetch('https://ntx-marketing.workflowcloud.com/api/v1/workflow/published/3ec1d398-843a-492f-a11c-1f80a76ea150/instances?token=C2bnCPTvZWAPLAkzH7XjcngrdBVcr6vwq8T8xf7tSsEBuJPmuoHZY8Oj9f7KwqHg0GiLlG', requestOptions);
            const data = await response
            this.savingCalculator(saving, perEnvelopsSaving);
        }else{
            console.log('required fields');
        }
    }

    getPartnerId(){
        let urlParams = window.location.search.split('&');
        let partner_id = null;
        if(null != urlParams && undefined != urlParams) {
            for(const param of urlParams) {
                let currentParamData = param.split('=');
                if(currentParamData[0]= 'utm_partnerid') {
                    partner_id = currentParamData[1];
                }
            }
        }
        return partner_id;
    }


    savingCalculator(saving,perEnvelopsSaving){
        
        var path = '/thankyou/'+perEnvelopsSaving+ '/' + saving+'/'+this.state.value;
        window.location = path;
    }

    handleRenewal(e) {
        this.setState({
            renewal: e.target.value
        })
    }


    render() {
        const { value, eSignatureValue } = this.state
        return (
            <>
                <section className="d-flex align-items-center position-relative overflow-hidden pb-lg-5 ">
                    <div className="container" id="nintex-saving-container">
                        <form onSubmit={this.submitData}>
                        <div className="row ">
                            
                            <div className="col-lg-6 text-center text-lg-start m-0 nintex-side-div pt-5">
                                <div className="nintex-savings">
                                    <img src={saving} />
                                    <h3 className=" fw-bold "> Savings estimator</h3>
                                </div>
                                <hr className="my-4" />
                                <div className="card-body">
                                    <div className="d-flex flex-stack">
                                        <div className="d-flex align-items-center nintex-section-2-spacing-2">
                                            <div className="flex-grow-1">
                                                <span className="text-gray-400 fw-normal d-block fs-6">How many eSignature envelopes do you send annually (or plan to send)?</span>
                                            </div>
                                        </div>
                                        <div className="d-flex slider  nintex-section-2-spacing nintex-numbers">
                                        <CurrencyFormat value={value} displayType={'text'} thousandSeparator={true}  renderText={value => <div><h3 className="mb-0 pb-1">{value}</h3></div>} />
                                            
                                            <div className="">
                                            <div className='slider' id="slider">
                                                <Slider
                                                    style={{ backgroundColor: '#ff6d00 !important' }}
                                                    min={2500}
                                                    max={1000000}
                                                    step ={500}
                                                    value={value}
                                                    onChange={this.handleChange}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className=" my-4" />
                                    <div className="separator separator-dashed my-4"></div>
                                    <div className="d-flex flex-stack">
                                        <div className="d-flex align-items-center nintex-section-2-spacing-2  ">
                                            <div className="flex-grow-1">
                                                <span className="text-gray-400  fw-normal d-block fs-6 ">What price are you currently paying per eSignature (or what you’ve been quoted) in USD?</span>
                                            </div>
                                        </div>
                                        <div className="d-flex nintex-section-2-spacing nintex-numbers">
                                            <CurrencyFormat value={eSignatureValue} displayType={'text'} prefix={'$'} decimalScale={2}
        fixedDecimalScale={true} decimalSeparator="." renderText={eSignatureValue => <div><h3 className="mb-0 pb-1">{eSignatureValue}</h3></div>} />
                                            <div className="">
                                                <div className='slider' id="slider">
                                                    <Slider
                                                        min={0.50}
                                                        max={4}
                                                        step={0.05}
                                                        value={eSignatureValue}
                                                        onChange={this.handleChangeeSignatureValue}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="d-flex flex-stack">
                                        <div className="d-flex align-items-center  nintex-section-2-spacing-2">
                                            <div className="flex-grow-1">
                                                <span className="text-gray-400 fw-normal d-block fs-6"> Who is your current eSignature provider?*</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center  nintex-section-2-spacing">
                                            <select className="form-select" aria-label="Default select example" name="provider" id="provider" required>
                                                <option value=""  >Select ....</option>
                                                <option value="DocuSign">DocuSign</option>
                                                <option value="AdobeSign">Adobe Sign</option>
                                                <option value="PandaDoc">PandaDoc</option>
                                                <option value="Conga">Conga</option>
                                                <option value="SignNow">SignNow</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="d-flex flex-stack">
                                        <div className="d-flex align-items-center  nintex-section-2-spacing-2">
                                            <div className="flex-grow-1">
                                                <span className="text-gray-400 fw-normal d-block fs-6">Which CRM system does your organization use?*</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center  nintex-section-2-spacing">
                                            <select className="form-select" aria-label="Default select example" name="crmUsed" id="crmUsed" required>
                                                <option value=""  >Select ....</option>
                                                <option value="Salesforce">Salesforce</option>
                                                <option value="Microsoft CRM">Microsoft CRM</option>
                                                <option value="Hubspot">Hubspot</option>
                                                <option value="Netsuite">Netsuite</option>
                                                <option value="SAP Sales Cloud">SAP Sales Cloud</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="d-flex flex-stack">
                                        <div className="d-flex align-items-center  nintex-section-2-spacing-2">
                                            <div className="flex-grow-1">
                                                <span className="text-gray-400 fw-normal d-block fs-6">When would you like to start (or when is your next renewal date)?*</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center  nintex-section-2-spacing">
                                            <select className="form-select" aria-label="Default select example" name="renewTime" id="renewTime" required>
                                                <option value=""  >Select ....</option>
                                                <option value="Now">Now</option>
                                                <option value="1-3month">1-3 months</option>
                                                <option value="6-9months">6-9 months</option>
                                                <option value="12+ months">12+ months</option>
                                                <option value="Just browsing for now">Just browsing for now</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                </div>
                            </div>
                            <div className="col-lg-1 text-center text-lg-start m-0 nintex-side-div pt-5"></div>
                            <Form ></Form>
                        </div>
                        </form>
                    </div>
                </section>
            </>
        )
    }

}


export default Saving