import React from "react";
import'../../assets/css/style.css'
import '../../assets/js/script.js'
import Header from "../header/Header";
class Layout extends React.Component {
    super() {

    }

    render() {
        return (
            <>
                <Header></Header>  
            </>
        )
    }

}


export default Layout