import React from 'react'
import Main_page_Mid from '../../assets/images/Group 150.png'

class MiddleBanner extends React.Component {

    super() {

    }

    render() {
        return (
            <>

                <section className="nintex-image-section pb-5">
                    <img src={Main_page_Mid} style={{borderRadius: "50%", position: "absolute",top: "100px", zIndex: "20"}} />
                </section>
                {/* <section className="nintex-image-section">
                    <img src={Main_page_Mid} style={{ borderRadius: "50%", position: "absolute", top: "0px" }} />
                </section> */}
                {/* <section className="  position-relative  bg-size-cover  overflow-hidden pt-6  nintex-mid-section">
                </section> */}
            </>
        )
    }

}


export default MiddleBanner